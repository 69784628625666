<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- nav menu toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- left col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ $store.getters["data/user"].fullName }}
            </p>
            <span class="user-status">{{
              $store.getters["data/user"].role == 1 ? "Admin" : "User"
            }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-info"
            badge
            :src="require('@/assets/images/avatars/default.jpg')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          v-b-modal.modal-profile
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Profile</span>
        </b-dropdown-item>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          v-b-modal.modal-setting
        >
          <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
          <span>Pengaturan</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <profile-component />
    <setting-component />
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  VBModal,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import profile from "@/views/components/profile.vue";
import setting from "@/views/components/settingModal.vue";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import cookie from "js-cookie";
import moment from "moment";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    DarkToggler,
    "profile-component": profile,
    "setting-component": setting,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    logout() {
      // remove cookie
      cookie.remove(useJwt.jwtConfig.cookieAccessToken);
      cookie.remove(useJwt.jwtConfig.cookieRefreshToken);
      cookie.remove(useJwt.jwtConfig.cookieUserData);
      cookie.remove("fromDate");
      cookie.remove("toDate");
      // reset state
      let params = {
        from_date: moment().set({ hour: "00", minute: "00" }),
        to_date: moment().set({ hour: "23", minute: "59" }),
      };
      this.$store.commit("data/SET_FILTER_DATE", params);
      // reset ability
      this.$ability.update(initialAbility);
      // redirect to login page
      this.$router.push({ name: "login" });
    },
  },
  directives: {
    "b-modal": VBModal,
  },
};
</script>
