<template>
  <div
    class="app-content content"
    :class="[
      { 'show-overlay': $store.state.app.shallShowOverlay },
      $route.meta.contentClass,
    ]"
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    >
      <b-alert
        v-if="is_show_notif"
        v-height-fade
        show
        dismissible
        fade
        class="mb-2"
        variant="danger"
        @dismissed="is_show_notif = false"
      >
        <div class="d-flex align-items-center p-1">
          <i class="bi bi-exclamation-circle mr-05"></i>
          <span>
            <strong> Perhatian! </strong>
            {{ text_alert }}
          </span>
        </div>
      </b-alert>
      <slot name="breadcrumb">
        <app-breadcrumb v-if="$route.name != 'dashboard'" />
      </slot>
      <div class="content-body">
        <transition :name="routerTransition" mode="out-in">
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import AppBreadcrumb from "@core/layouts/components/AppBreadcrumb.vue";
import useAppConfig from "@core/app-config/useAppConfig";
import { BAlert } from "bootstrap-vue";
import { heightFade } from "@core/directives/animations";
import Ripple from "vue-ripple-directive";
import { ref } from "@vue/composition-api";
import { io } from "socket.io-client";

export default {
  components: {
    BAlert,
    AppBreadcrumb,
  },
  directives: {
    heightFade,
    Ripple,
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig();
    const is_show_notif = ref(false);
    const text_alert = ref("");

    function listenNotification() {
      const socket = io(process.env.VUE_APP_SOCKET_URL, {
        query: { username: "vm_usage" },
      });
      socket.on("notif-usage", (res) => {
        this.is_show_notif = true;
        this.text_alert = res.message;
      });
    }

    return {
      routerTransition,
      contentWidth,
      is_show_notif,
      text_alert,
      listenNotification,
    };
  },
  mounted() {
    this.listenNotification();
  },
};
</script>
