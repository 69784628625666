<template>
  <b-modal
    id="modal-profile"
    size="lg"
    hide-footer
    no-close-on-backdrop
  >
    <!-- custom modal title -->
    <template #modal-title>
      <div class="d-flex align-items-center">
        <feather-icon size="20" icon="UserIcon" class="mr-05" />
        <span class="fs-18">User Profile</span>
      </div>
    </template>
    <div class="row">
      <!-- data diri -->
      <div class="col-md-7">
        <b-card no-body class="h-100">
          <b-card-header>
            <span class="fs-16 font-weight-bolder">Informasi Akun</span>
          </b-card-header>
          <b-card-body>
            <b-alert
              variant="success"
              :show="close_change_user"
              @dismissed="close_change_user = 0"
              class="px-1"
              style="padding-top: 5px; padding-bottom: 5px"
            >
              <span class="d-flex align-items-center">
                <feather-icon icon="CheckCircleIcon" class="mr-05" />
                <small>Informasi Akun berhasil diupdate</small>
              </span>
            </b-alert>
            <validation-observer ref="biodataRules">
              <b-form @submit.prevent="updateBiodata">
                <!-- nama lengkap -->
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Nama Lengkap"
                    rules="required"
                  >
                    <label for="nama-lengkap">
                      Nama Lengkap <span class="text-danger">*</span>
                    </label>
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UserCheckIcon" />
                      </b-input-group-prepend>

                      <b-form-input
                        v-model="user_data.fullName"
                        id="nama-lengkap"
                        placeholder="Nama Lengkap"
                      />
                    </b-input-group>
                    <small class="text-danger" style="font-size: 10px">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- username -->
                <b-form-group>
                  <label for="username">
                    Username <span class="text-danger">*</span>
                  </label>
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="UserIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="user_data.username"
                      readonly
                      id="username"
                      placeholder="Username"
                    />
                  </b-input-group>
                </b-form-group>
                <!-- email -->
                <b-form-group>
                  <validation-provider #default="{ errors }" name="Email">
                    <label for="email">Email</label>
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="MailIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="user_data.email"
                        id="email"
                        placeholder="Email"
                        type="text"
                      />
                    </b-input-group>
                    <small class="text-danger" style="font-size: 10px">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- button simpan -->
                <b-button
                  type="submit"
                  variant="outline-info"
                  size="sm"
                  class="float-right"
                >
                  Simpan
                </b-button>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-card>
      </div>
      <!-- ubah password -->
      <div class="col-md-5">
        <b-card no-body class="h-100">
          <b-card-header>
            <span class="fs-16 font-weight-bolder">Ubah Password</span>
          </b-card-header>
          <b-card-body>
            <b-alert
              variant="success"
              :show="close_change_password"
              @dismissed="close_change_password = 0"
              class="px-1"
              style="padding-top: 5px; padding-bottom: 5px"
            >
              <span class="d-flex align-items-center">
                <feather-icon icon="CheckCircleIcon" class="mr-05" />
                <small> Password Berhasil diupdate </small>
              </span>
            </b-alert>
            <b-alert
              variant="danger"
              :show="is_wrong_passsword"
              class="px-1"
              style="padding-top: 5px; padding-bottom: 5px"
            >
              <span class="d-flex align-items-center">
                <feather-icon
                  v-if="retry_change_password <= 3"
                  icon="XCircleIcon"
                  class="mr-05"
                />
                <small class="text-justify">
                  {{
                    is_wrong_passsword && retry_change_password <= 3
                      ? "Password lama salah!"
                      : "Anda telah melakukan percobaan sebanyak 3 kali!. Tunggu beberapa saat untuk mengganti password kembali"
                  }}
                  <span v-if="retry_change_password > 3">
                    <small> {{ time_left }} </small>
                  </span>
                </small>
              </span>
            </b-alert>
            <validation-observer ref="passwordRules">
              <b-form @submit.prevent="updatePassword">
                <!-- password lama -->
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Password Lama"
                    rules="required"
                  >
                    <label for="password-lama">
                      Password Lama <span class="text-danger">*</span>
                    </label>
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="KeyIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="password_lama"
                        type="password"
                        id="password-lama"
                        placeholder="Password Lama"
                        :disabled="retry_change_password > 3"
                      />
                    </b-input-group>
                    <small class="text-danger" style="font-size: 10px">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- password baru -->
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Password Baru"
                    vid="Password"
                    rules="required"
                  >
                    <label for="password-baru">
                      Password Baru <span class="text-danger">*</span>
                    </label>
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="KeyIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="password_baru"
                        type="password"
                        id="password-baru"
                        placeholder="Password Baru"
                        :disabled="retry_change_password > 3"
                      />
                    </b-input-group>
                    <small class="text-danger" style="font-size: 10px">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- konfirmasi password baru -->
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Konfirmasi Password"
                    rules="required|confirmed:Password"
                  >
                    <label for="confirm-password-baru">
                      Konfirmasi Password Baru
                      <span class="text-danger">*</span>
                    </label>
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="KeyIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="confirm_password"
                        type="password"
                        id="confirm-password-baru"
                        placeholder="Konfirmasi Password Baru"
                        :disabled="retry_change_password > 3"
                      />
                    </b-input-group>
                    <small class="text-danger" style="font-size: 10px">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- button ubah password -->
                <b-button
                  v-if="retry_change_password <= 3"
                  type="submit"
                  variant="outline-info"
                  size="sm"
                  class="float-right"
                >
                  Ubah Password
                </b-button>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-card>
      </div>
    </div>
  </b-modal>
</template>
<script>
import {
  BModal,
  VBModal,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BAlert,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, confirmed } from "@validations";
import useJwt from "@/auth/jwt/useJwt";

export default {
  name: "profile",
  data() {
    return {
      is_wrong_passsword: false,
      retry_change_password: 0,
      time_left: 60,
      retry_timer: null,
      close_change_user: 0,
      close_change_password: 0,
      user_data: {
        ability: [],
        disabled: null,
        fullName: "",
        id: null,
        id_project: null,
        role: null,
        username: "",
        email: "",
      },
      password_lama: "",
      password_baru: "",
      confirm_password: "",
      //   validation
      required,
      confirmed,
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BAlert,
  },
  created() {
    this.user_data = {
      ability: [],
      disabled: this.$store.getters["data/user"].disabled,
      fullName: this.$store.getters["data/user"].fullName,
      id: this.$store.getters["data/user"].id,
      id_project: this.$store.getters["data/user"].id_project,
      role: this.$store.getters["data/user"].role,
      username: this.$store.getters["data/user"].username,
      email: this.$store.getters["data/user"].email,
    };
  },
  mounted() {},
  methods: {
    updateBiodata() {
      this.$refs.biodataRules.validate().then((success) => {
        if (success) {
          let api =
            process.env.VUE_APP_API_URL + "users/edit/" + this.user_data.id;
          let params = {
            nama: this.user_data.fullName,
            username: this.user_data.username,
            email: this.user_data.email ? this.user_data.email : "-",
            role: this.user_data.role,
          };
          useJwt.axiosIns
            .put(api, { data: params })
            .then(() => {
              this.$store.commit("data/SET_USER", this.user_data);
              this.close_change_user = 5;
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    updatePassword() {
      this.retry_change_password++;
      this.is_wrong_passsword = false;
      this.$refs.passwordRules.validate().then((success) => {
        if (success) {
          let api = process.env.VUE_APP_API_URL + "users/change-password";
          let params = {
            old_password: this.password_lama,
            new_password: this.password_baru,
            user_id: this.user_data.id,
          };
          useJwt.axiosIns
            .post(api, { data: params })
            .then(() => {
              this.close_change_password = 5;
            })
            .catch((err) => {
              console.log(err);
              this.is_wrong_passsword = true;
            });
        }
      });
      if (this.retry_change_password > 3) {
        this.retry_timer = setInterval(() => {
          this.time_left--;
          if (this.time_left === 0) {
            clearInterval(this.retry_timer);
            this.retry_change_password = 0;
            this.is_wrong_passsword = false;
            this.time_left = 60;
          }
        }, 1000);
      }
    },
  },
  directives: {
    "b-modal": VBModal,
  },
};
</script>
<style>
.modal-title {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 20px !important;
  font-weight: bold;
}
</style>